import { useEffect, useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import Select from "react-select";

import "../App.css";
import { getSelectStyle } from "../util/util";

function FlowDownload({
  defaultFlow,
  setURL,
  theme,
  step,
  setDownloadFlow,
  downloadFlow,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [flowIDs, setFlowIDs] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetch("/api/getFlows")
      .then((res) => res.json())
      .then((data) => {
        const flowData = data.body;
        const flowObjs = flowData.map((flowObj) => ({
          value: flowObj.id,
          label: flowObj.name,
        }));

        setOptions(flowObjs);
        setFlowIDs(flowData);
        setIsLoading(false);
        setDownloadFlow(flowData.find((obj) => obj.id === defaultFlow));
      })
      .catch((err) => console.log("Error: ", err));
  }, [defaultFlow, setDownloadFlow]);

  const handleDownload = (e) => {
    e.preventDefault();
    fetch("/api/downloadFlow", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        flowid: downloadFlow.id,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((jsonData) => {
        const exportData = jsonData.exportData;
        const exportBlob = new Blob([JSON.stringify(exportData, null, 2)], {
          type: "application/json",
        });

        const fileName = `${downloadFlow.id}.json`;
        saveAs(exportBlob, fileName);
      })
      .catch((err) => console.log("Error: ", err));
  };

  return (
    <div className="page">
      {!isLoading && flowIDs && downloadFlow ? (
        <>
          <Select
            options={options
              .sort((a, b) => {
                const labelA = a.label.toLowerCase();
                const labelB = b.label.toLowerCase();

                // Check if either option starts with a non-letter
                const startsWithNonLetterA = !labelA[0].match(/[a-z]/i);
                const startsWithNonLetterB = !labelB[0].match(/[a-z]/i);

                // Sort options with non-letters at the end
                if (startsWithNonLetterA && !startsWithNonLetterB) {
                  return 1;
                } else if (!startsWithNonLetterA && startsWithNonLetterB) {
                  return -1;
                }

                // Sort alphabetically within the same group
                return labelA.localeCompare(labelB);
              })
              .filter((flowObj) => !flowObj.label.startsWith("_"))}
            styles={getSelectStyle(theme)}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={(flow) => {
              setURL("", "", flow.value);
              setDownloadFlow(flowIDs.find((obj) => obj.id === defaultFlow));
            }}
            value={options.filter((flow) => flow.value === defaultFlow)}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
          <button className="download-btn" onClick={(e) => handleDownload(e)}>
            <DownloadIcon />
          </button>
        </>
      ) : (
        <>
          <p>Loading...</p>
        </>
      )}
    </div>
  );
}

export default FlowDownload;
